const mutations = {
  /**
   * @param state
   * @param shouldShowEmailSupport
   */
  SET_SHOULD_SHOW_EMAIL_SUPPORT (state, shouldShowEmailSupport) {
    state.shouldShowEmailSupport = shouldShowEmailSupport
  },
  /**
   * @param state
   * @param isChatbotOpen
   */
  SET_IS_CHATBOT_OPEN (state, isChatbotOpen) {
    state.isChatbotOpen = isChatbotOpen
  }
}

export default mutations
